import React, { useState, useEffect } from 'react'

import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import { DatabaseService } from 'services'
import ItemList from './ItemList'
import ItemEdit from './ItemEdit'

function InventoryList(props) {
  const [items, setItems] = useState(null)

  useAsyncEffect(async () => {
    const itemsSnap = await DatabaseService.getCurrentUserItems()
    setItems(
      itemsSnap.docs.map(docSnap => {
        return { id: docSnap.id, ...docSnap.data() }
      })
    )
  }, [])

  // still loading
  if (items === null) {
    return null
  }

  return <ItemList items={items} />
}

export default function InventoryTab(props) {
  const { item = null, location: { state = {} } = {} } = props

  const [itemData, setItemData] = useState(state)

  useEffect(() => {
    setItemData(state || {})
  }, [state])

  if (itemData.item) {
    return <ItemEdit location={props.location} />
  }

  return <InventoryList {...props} />
}
