import React, { useState } from 'react'
import { StaticQuery } from 'gatsby'

import {
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  GoogleIcon,
} from 'components/shared/Icons'
import styles from './ShareBtn.module.scss'

function ShareBtn(props) {
  const url = 'https://melo.to'
  let { link, text } = props
  const [clicked, setClicked] = useState(false)

  return (
    <div className={styles.shareBtn} onClick={() => setClicked(true)}>
      {!clicked ? (
        <div className={styles.shareTxt}>Share</div>
      ) : (
        <div className={styles.shareRow}>
          <a
            href={`https://twitter.com/share?url=${url +
              link}&amp;text=${text}`}
            className={styles.shareItem}
            target="_blank"
          >
            <TwitterIcon className={styles.socialIcon} />
          </a>
          <a
            href={`http://www.facebook.com/sharer.php?u=${url + link}`}
            className={styles.shareItem}
            target="_blank"
          >
            <FacebookIcon className={styles.socialIcon} />
          </a>
          <a
            href={`http://reddit.com/submit?url=${url + link}`}
            className={styles.shareItem}
            target="_blank"
          >
            <InstagramIcon className={styles.socialIcon} />
          </a>
          <a
            href={`https://plus.google.com/share?url=${url + link}`}
            className={styles.shareItem}
            target="_blank"
          >
            <GoogleIcon className={styles.socialIcon} />
          </a>
        </div>
      )}
    </div>
  )
}

export default ShareBtn
