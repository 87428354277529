import React, { useState, useLayoutEffect } from 'react'

import styles from './TabNavigator.module.scss'

function Tab(props) {
  const { children, active, ...containerProps } = props
  return <li {...containerProps}>{children}</li>
}

export default function TabNavigator(props) {
  const {
    children,
    tabs = [],
    selected = 0,
    onSelectTab,
    tabClass = styles.tabItem,
    activeTabClass = styles.active,
    className = '',
    tabContainerClass = '',
    tabContainerProps = {},
    tabProps = {},
    ...containerProps
  } = props

  const [activeTabIndex, setActiveTabIndex] = useState(
    Math.max(0, Math.min(selected, tabs.length))
  )

  // Allows the active tab to change via props
  useLayoutEffect(() => {
    if (activeTabIndex === selected) {
      return
    }
    setActiveTabIndex(Math.max(0, Math.min(selected, tabs.length)))
  }, [selected])

  function setActiveTab(index) {
    if (typeof onSelectTab === 'function' && onSelectTab(index) === false) {
      return
    }
    setActiveTabIndex(index)
  }

  function getActiveTab() {
    return React.Children.toArray(children)[activeTabIndex]
  }

  function getTabSelectors() {
    return tabs.map((tabProps, i) => {
      if (typeof tabProps === 'string') {
        tabProps = { content: tabProps }
      }
      const { content } = tabProps
      const isActive = i === activeTabIndex
      return (
        <Tab
          key={`tab-${i}`}
          className={[
            styles.tabClass,
            tabClass,
            isActive ? activeTabClass : '',
          ].join(' ')}
          active={isActive}
          onClick={() => setActiveTab(i)}
          {...tabProps}
        >
          {typeof content === 'function' ? content(isActive) : content}
        </Tab>
      )
    })
  }

  return (
    <div
      className={[styles.container, className].join(' ')}
      {...containerProps}
    >
      <ul
        className={[styles.tabList, tabContainerClass].join(' ')}
        {...tabContainerProps}
      >
        {getTabSelectors()}
      </ul>
      {getActiveTab()}
    </div>
  )
}
