import React, { useState } from 'react'
import { navigate } from 'gatsby'

import styles from './SettingsTab.module.scss'
import { DatabaseService, AuthService } from 'services'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import { FormCheckbox, Alert } from 'components/shared/Forms'

export default function SettingsTab(props) {
  const { profile } = props

  const {
    stripe_user_id,
    emailVerified,
    phoneVerified,
    socialVerified,
    newsletterSubscribed,
  } = profile

  const [isSubscribed, setSubscribed] = useState(newsletterSubscribed)

  const onToggleSubscription = async value => {
    try {
      await DatabaseService.updateSubscribe('email', value)
      setSubscribed(value)
    } catch (error) {
      console.log(error)
    }
  }

  const [phoneError, setPhoneError] = useState(false)

  const onToggleTextAlerts = async value => {
    if (true) {
      setPhoneError(true)
    } else {
      try {
        await DatabaseService.updateSubscribe('text', value)
        setSubscribed(value)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const [verificationSent, setVerification] = useState(false)

  const sendVerificationEmail = async () => {
    try {
      const response = await AuthService.verifyEmail()
      setVerification(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Card>
      <div className={styles.section}>
        <h4 className={styles.header}>Payment</h4>
        <FormCheckbox
          className={styles.checkRowContainer}
          checked={stripe_user_id}
          disabled={true}
        >
          <div className={styles.checkRowInner}>
            <div>Stripe account {stripe_user_id ? '' : 'not'} activated</div>
            {!stripe_user_id && (
              <Button small to="/onboarding">
                activate
              </Button>
            )}
          </div>
        </FormCheckbox>
      </div>

      <div className={styles.section}>
        <h4 className={styles.header}>Verification</h4>
        <FormCheckbox
          className={styles.checkRowContainer}
          checked={phoneVerified}
          disabled={phoneVerified}
        >
          <div className={styles.checkRowInner}>
            <div>
              Phone number {phoneVerified ? '' : 'has not been'} verified
            </div>
            {!phoneVerified && (
              <Button small onClick={() => sendVerificationEmail()}>
                verify
              </Button>
            )}
          </div>
        </FormCheckbox>
        <FormCheckbox
          className={styles.checkRowContainer}
          checked={emailVerified}
          disabled={emailVerified}
        >
          <div className={styles.checkRowInner}>
            {!verificationSent ? (
              <>
                <div>Email {emailVerified ? ' ' : 'has not been'} verified</div>
                {!emailVerified && (
                  <Button small onClick={() => sendVerificationEmail()}>
                    verify
                  </Button>
                )}{' '}
              </>
            ) : (
              <div>Check your email for a verification link</div>
            )}
          </div>
        </FormCheckbox>
        <FormCheckbox
          className={styles.checkRowContainer}
          checked={socialVerified}
          disabled={socialVerified != null}
        >
          <div className={styles.checkRowInner}>
            <div>
              Social proof{' '}
              {socialVerified
                ? ''
                : socialVerified == false
                ? 'is being'
                : 'not'}{' '}
              verified
            </div>
            {socialVerified == null && (
              <Button to="/users/verify_social" small>
                verify
              </Button>
            )}
          </div>
        </FormCheckbox>
      </div>

      <div className={styles.section}>
        <h4 className={styles.header}>Notifications</h4>
        <FormCheckbox
          className={styles.checkRowContainer}
          checked={socialVerified}
          onChange={e => onToggleTextAlerts(e.target.checked)}
          disabled={!phoneVerified}
        >
          <div>
            Send me text message alerts about rental requests.{' '}
            {!phoneVerified && '(Verify your phone number first)'}
          </div>
        </FormCheckbox>
        <FormCheckbox
          className={styles.checkRowContainer}
          checked={newsletterSubscribed}
          onChange={e => onToggleSubscription(e.target.checked)}
        >
          <div>
            Email me Borrowed updates. This does not include transactional
            emails.
          </div>
        </FormCheckbox>
      </div>
      <Alert
        type="error"
        visible={phoneError}
        message="Please verify your phone number first"
      />
    </Card>
  )
}
