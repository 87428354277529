import React, { useState } from 'react'
import { Match } from '@reach/router'

import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import { DatabaseService } from 'services'
import ItemRentalList from './ItemRentalList'
import Switcher from 'components/shared/Switcher'

export default function RentalsTab(props) {
  const [history, setHistory] = useState(null)
  const [selected, setSelected] = useState(0)
  useAsyncEffect(async () => {
    const history = await Promise.all([
      DatabaseService.getCurrentUserRentalHistory(),
      DatabaseService.getCurrentUserLoanerHistory(),
    ])
    setHistory(
      history.map(qSnap => {
        return qSnap.docs.map(docSnap => {
          const doc = { id: docSnap.id, ...docSnap.data() }
          if (doc.item) {
            doc.item_id = doc.item.id
          }
          // convert the dates
          doc.start_date &&
            doc.start_date.toDate &&
            (doc.start_date = doc.start_date.toDate())
          doc.end_date &&
            doc.end_date.toDate &&
            (doc.end_date = doc.end_date.toDate())
          return doc
        })
      })
    )
  }, [])

  if (history === null) {
    return null
  }

  return (
    <>
      <Switcher
        tabs={[{ content: 'Renting' }, { content: 'Lending' }]}
        onSelectTab={tab => setSelected(tab)}
      />
      <ItemRentalList items={history[selected]} lending={selected === 1} />
    </>
  )
}
