import React from 'react'
import { Link } from 'gatsby'

import styles from './ItemList.module.scss'
import List from 'components/shared/Lists/List'
import { InventoryEmpty } from 'components/DashboardPage/EmptyLists'
import { getRoute } from 'utils/index'
import Button from 'components/shared/Button'
import { convertToMoney } from 'utils/conversions'

function Item({ data }) {
  return (
    <div
      className={[styles.container, styles.expandable].join(' ')}
      key={data.id}
    >
      <div className={styles.detailsContainer}>
        <div className={styles.imgContainer}>
          <Link to={`item/${data.id}`}>
            <img
              src={data.img || data.picture || data.pictures[0]}
              className={styles.coverImg}
            />
          </Link>
        </div>
        <div className={styles.description}>
          <div className={styles.title}>{data.name}</div>
          <div className={styles.price}>
            <span>{convertToMoney(data.price.day)} per day</span>
          </div>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Link
          to={getRoute('EDIT_ITEM', { id: data.id })}
          state={{
            item: {
              id: data.id,
              pictures: data.pictures,
              name: data.name,
              type: data.type,
              subtype: data.subtype,
              description: data.description,
              zipcode: data.zipcode,
              price: data.price,
            },
          }}
        >
          <Button accentInverse>Edit</Button>
        </Link>
      </div>
    </div>
  )
}

export default ({ items }) => (
  <List items={items} emptyComponent={InventoryEmpty} itemComponent={Item} />
)
