import React from 'react'
import { Match, Link } from '@reach/router'
import { observer } from 'mobx-react-lite'

import styles from './DashboardPage.module.scss'
import { ProfileTab, SettingsTab, InventoryTab, RentalsTab } from './Tabs'
import UserStateStore from 'state/UserStateStore'
import TabNavigator from 'components/shared/TabNavigator'
import UserCard from 'components/Users/UserCard'

const DashboardTabs = observer(props => {
  const { match, location, navigate } = props
  const profile = UserStateStore.user

  if (!profile) {
    return null
  }
  const tabs = [
    {
      content: 'Profile',
    },
    {
      content: 'Rental History',
      route: 'rentals',
    },
    {
      content: 'Inventory',
      route: 'items',
    },
    {
      content: 'Settings',
      route: 'settings',
    },
  ]
  const activeTab = Math.max(
    0,
    tabs.findIndex(tab => {
      return match === null
        ? 0
        : tab.route && tab.route.toLowerCase() === match.tab.toLowerCase()
    })
  )
  return (
    <TabNavigator
      tabs={tabs}
      tabContainerClass={styles.tabContainer}
      tabContainerProps={{
        style: { '--num_menu_items': tabs.length, '--index': activeTab },
      }}
      activeTabClass={styles.active}
      selected={activeTab}
      onSelectTab={tab => navigate(`/dashboard/${tabs[tab].route || ''}`)}
    >
      <ProfileTab match={match} profile={profile} />
      <RentalsTab profile={profile} />
      <InventoryTab location={location} match={match} />
      <SettingsTab match={match} profile={profile} />
    </TabNavigator>
  )
})

export default function({ match, location, navigate }) {
  const profile = UserStateStore.user
  return (
    <div className={styles.container}>
      <div className={styles.dashboardContainer}>
        <div className={styles.sideBar}>
          <UserCard user={profile} showViewProfile />
        </div>
        <div className={styles.contentWrapper}>
          <DashboardTabs
            navigate={navigate}
            location={location}
            match={match}
          />
        </div>
      </div>
    </div>
  )
}
