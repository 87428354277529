import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import styles from './List.module.scss'
import Melo from 'components/shared/Brand/Melo'
import Borrowed from 'components/shared/Brand/Borrowed'
import { LoadingDots } from 'components/shared/Loading'

export default function({
  items,
  itemComponent: Item,
  perPage = 10,
  loading,
  emptyComponent: Empty,
  showTotal = true,
  showPowered = true,
  className,
  ...props
}) {
  const [currentPage, setPage] = useState(1)
  const [listItems, setListItems] = useState(items.slice(0, 10))
  const pageCount = Math.ceil(Math.max(items.length / perPage, 1))

  useEffect(() => {
    const offset = (currentPage - 1) * perPage
    setListItems(items.slice(offset, offset + perPage))
  })

  const onPageChange = i => {
    setPage(i)
  }

  if (loading) {
    return <LoadingDots loading={true} style={{ marginTop: 150 }} />
  }

  return (
    <div className={[styles.container, className].join(' ')}>
      {showTotal && <div className={styles.total}>Total: {items.length}</div>}
      <div className={styles.listContainer}>
        {listItems.map(data => {
          return <Item key={`data-${data.id}`} data={data} />
        })}
        {items.length == 0 && !loading && <Empty {...props} />}

        <div className={styles.listFooter}>
          <div className={styles.poweredBy}>
            {showPowered && (
              <>
                <Borrowed color="#ced4da" />
              </>
            )}
          </div>
          <div className={styles.paginationWrapper}>
            <div className={styles.paginationContainer}>
              <span className={styles.paginationLabel}>
                {currentPage} of {pageCount}
              </span>
              <ul className={styles.paginationArrows}>
                <li className={styles.paginationtPrev}>
                  <a className={styles.paginationLink} />
                </li>
                <ReactPaginate
                  previousLabel={'‹'}
                  nextLabel={'›'}
                  breakClassName={'break'}
                  pageCount={pageCount}
                  onPageChange={data => onPageChange(data.selected + 1)}
                  containerClassName={styles.paginationArrows}
                  activeClassName={'active'}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
