import React from 'react'
import styled from '@emotion/styled'

import { colors, fonts } from 'utils/styles'

const Label = styled('span')`
  font-family: ${fonts.body};
  border-radius: 4px;
  padding: 6px 10px;
  font-weight: 600;
  text-transform: lowercase;
  font-size: 11px;
  &.Canceled {
    color: #e39f65;
    background-color: #f9f6ee;
  }
  &.Awaiting {
    background-color: #eaf3fb;
    color: #006cd1;
  }
  &.Approved {
    color: #49b882;
    background-color: #e4f7ed;
  }
  &.Rejected {
    background-color: #faeff5;
    color: #e365a6;
  }
`

export const StatusTag = props => {
  let { item = {}, label } = props
  const { approved, cancelled } = item

  const getLabel = () => {
    if (label) {
      return label
    }
    if (cancelled) {
      return 'Canceled'
    }
    if (typeof approved === 'undefined') {
      return 'Awaiting Approval'
    }
    if (approved === true) {
      return 'Approved'
    }
    if (approved === false) {
      return 'Rejected'
    }
  }
  return (
    <div style={{ marginTop: 10 }}>
      <Label className={getLabel()}>{getLabel()}</Label>
    </div>
  )
}
