import React from 'react'
import { Link } from 'gatsby'
import { EmptyIcon } from 'components/shared/Icons'
import styles from './EmptyLists.module.scss'

export function RentalEmpty({ lending }) {
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.iconContainer}>
        <EmptyIcon />
      </div>
      {lending
        ? "You haven't lent out any items yet"
        : "You haven't rented an item yet"}
      <div className={styles.btnContainer}>
        {lending ? (
          <Link to="/submit">
            <button className={styles.btn}>List an item</button>
          </Link>
        ) : (
          <Link to="/search">
            <button className={styles.btn}>Find an item to rent</button>
          </Link>
        )}
      </div>
    </div>
  )
}

export function InventoryEmpty() {
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.iconContainer}>
        <EmptyIcon />
      </div>
      You don't have any items for rent
      <div className={styles.btnContainer}>
        <Link to="/submit">
          <button className={styles.btn}>List an item</button>
        </Link>
      </div>
    </div>
  )
}
