import React from 'react'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'

import './ItemEdit.scss'
import {
  FormInput,
  Uploader,
  FormButtonsElement,
  FormRow,
  FormCol,
} from 'components/shared/Forms'
import { DatabaseService } from 'services'
import Card from 'components/shared/Card'
import * as categories from 'constants/categories'

export default class ItemsTabBase extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      zipcode: '',
      id: '',
      price: {},
      type: '',
      subtype: '',
      description: '',
      img: '',
      pictures: [],
      intialUploadCare: [],
      imagesLoaded: false,
      saved: true,
      saving: false,
    }
  }

  onSubmit = async data => {
    try {
      await DatabaseService.updateItem(data)
    } catch (error) {
      return error
    }
  }

  onDeleteItem = async id => {
    try {
      await DatabaseService.deleteItem(id)
    } catch (error) {
      return error
    }
  }

  componentDidMount() {
    if (this.props.location.state) {
      let {
        name,
        id,
        zipcode,
        price,
        type,
        subtype,
        description,
        pictures,
      } = this.props.location.state.item
      const dollars = (price.day / 100).toFixed(0)
      this.setState({
        id,
        name,
        zipcode,
        price_d: dollars,
        subtype,
        description,
        pictures,
      })
      this.setInitialUploadCare(pictures)
    } else {
      navigate('/dashboard/items/')
    }
  }

  uploadImg = info => {
    let splitUrl = info.cdnUrl.split('~')
    let imgCount = parseInt(splitUrl[splitUrl.length - 1].replace('/', ''))
    let pictures = []
    for (var i = 0; i < imgCount; i++) {
      let imgUrl = info.cdnUrl + 'nth/' + i + '/'
      pictures.push(imgUrl)
    }
    this.setState({ pictures: pictures, saved: false })
  }

  _submit = () => {
    this.setState({ saving: true })
    let data = {}
    data.id = this.state.id
    data.name = this.state.name
    data.pictures = this.state.pictures
    data.zipcode = this.state.zipcode
    data.price_d = this.state.price_d
    data.type = this.state.type
    data.subtype = this.state.subtype
    data.description = this.state.description
    data.phone = this.state.user_phone
    ;(async () => {
      const error = await this.onSubmit(data)
      if (error) {
        this.setState({ saving: false, error })
      }
      this.setState({ saving: false, saved: true })
    })()
  }

  _delete = () => {
    this.setState({ deleting: true })
    let id = this.state.id
    ;(async () => {
      const error = await this.onDeleteItem(id)
      if (error) {
        this.setState(error)
      }
      navigate('/dashboard/items')
    })()
  }

  onChange = event => {
    this.setState({ saved: false, [event.target.name]: event.target.value })
  }

  setInitialUploadCare = pictures => {
    let intialUploadCare = []
    pictures.map(picture => {
      if (picture.includes('ucarecdn')) {
        let refID = picture
          .split('.com/')
          .pop()
          .split('/')[0]
        intialUploadCare.push(refID)
      }
    })
    this.setState({ intialUploadCare, imagesLoaded: true })
  }

  render() {
    const {
      name,
      zipcode,
      price_d,
      type,
      subtype,
      description,
      pictures,
      saving,
      saved,
      intialUploadCare,
      imagesLoaded,
    } = this.state

    return (
      <Card title="Edit">
        <form onSubmit={this.onSubmit}>
          <FormRow>
            <FormCol>
              <FormInput label="Name">
                <input
                  type="text"
                  name="name"
                  maxLength="250"
                  value={name}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="Price (Daily $)">
                <input
                  name="price_d"
                  type="number"
                  value={price_d}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="Category">
                <select name="type" value={type} onChange={this.onChange}>
                  <option value="" disabled="">
                    {' '}
                    Select category
                  </option>
                  {Object.keys(categories.ITEM_CATEGORIES).map(key => {
                    return (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  })}
                </select>
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="Sub-category">
                <select name="subtype" onChange={this.onChange}>
                  <option value="" disabled="">
                    {' '}
                    Select category
                  </option>
                  {type &&
                    categories.ITEM_CATEGORIES[type] &&
                    Object.keys(categories.ITEM_CATEGORIES[type]).map(key => {
                      return (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      )
                    })}
                </select>
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="Description">
                <textarea
                  className="text-input"
                  name="description"
                  type="text"
                  maxLength="1600"
                  rows="6"
                  value={description}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
        </form>
        <div className="item-edit">
          <div className="uploadcare-container">
            {pictures &&
              imagesLoaded &&
              pictures.map(picture => (
                <img key={picture} src={picture} height="100" width="100" />
              ))}
            {imagesLoaded && (
              <Uploader
                id="file"
                name="file"
                value={intialUploadCare}
                multiple={true}
                onUploadComplete={this.uploadImg}
              />
            )}
          </div>
        </div>
        <FormButtonsElement
          backText="Delete"
          submitText={saving ? 'Saving...' : saved ? 'Saved' : 'Save'}
          back={() => this._delete()}
          submit={() => this._submit()}
          disabled={saved}
        />
      </Card>
    )
  }
}
