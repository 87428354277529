import React from 'react'
import { Match, Link } from '@reach/router'

import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import Dashboard from 'components/DashboardPage'

export default ({ location }) => (
  <Layout location={location}>
    <SEO title="Dashboard" />
    <Match path={`/dashboard/:tab`}>
      {({ match, location, navigate }) => (
        <Dashboard match={match} location={location} navigate={navigate} />
      )}
    </Match>
  </Layout>
)
