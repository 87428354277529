import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'

import styles from './ItemRentalItem.module.scss'
import { StatusTag } from 'components/shared/Tags/StatusTag'
import { MoneyIcon, PersonIcon, CalendarIcon } from 'components/shared/Icons'
import { getRoute } from 'utils'
import { convertToMoney, toDate } from 'utils/conversions'
import Button from 'components/shared/Button'
import * as routes from 'constants/routes'

export default ({ data, lending }) => {
  const {
    id,
    item,
    item_id,
    item_img,
    item_name,
    start_date,
    end_date,
    renter,
    lender,
    renter_name,
    lender_name,
    price,
    approved,
    cancelled,
  } = data

  const [expanded, setExpanded] = useState(false)

  return (
    <div
      className={[styles.container, styles.expandable].join(' ')}
      key={id}
      onClick={() => setExpanded(!expanded)}
    >
      <div className={styles.content}>
        <div className={styles.detailsContainer}>
          <div className={styles.imgContainer}>
            <Link to={`/item/${item_id}/`}>
              <img src={item_img} className={styles.coverImg} />
            </Link>
          </div>
          <div className={styles.detailsActionContainer}>
            <div className={styles.description}>
              <div className={styles.title}>{item_name}</div>
              <div className={styles.price}>
                <CalendarIcon />
                <span>
                  {toDate(start_date, 'MMM Do')} - {toDate(end_date, 'MMM Do')}
                </span>
              </div>
              <div className={styles.price}>
                <PersonIcon />
                <span>{lending ? 'Renter' : 'Lender'}:</span>
                <Link
                  to={getRoute('PROFILE', {
                    id: lending ? renter.id : lender.id,
                  })}
                >
                  {lending ? renter_name : lender_name}
                </Link>
              </div>
              <div className={styles.price}>
                <MoneyIcon />
                <span>{convertToMoney(price.price)} /day</span>
              </div>
              {expanded && (
                <div>
                  <div className={styles.price}>
                    Service Fee: {convertToMoney(price.fee)}
                  </div>
                  <div className={styles.price}>
                    Total Price: {convertToMoney(price.total)}
                  </div>
                  <div className={styles.price}>Rental ID: #{id} </div>
                </div>
              )}
              <StatusTag item={item} />
            </div>
            <div className={styles.btnContainer}>
              {lending && typeof approved === 'undefined' && !cancelled && (
                <Button
                  accentInverse
                  small
                  onClick={() => navigate(`/rental-confirmation?id=${id}`)}
                >
                  confirm/reject
                </Button>
              )}
              {expanded && (
                <Button
                  accentInverse
                  small
                  href={`mailto:${
                    routes.EMAIL
                  }?Subject=Rental%20Issue%20ID:%20#${id}`}
                >
                  Report Issue
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.viewMore}>view {expanded ? 'less' : 'more'}</div>
    </div>
  )
}
