import React from 'react'

import styles from './UserCard.module.scss'
import UserBlurb from 'components/Users/UserBlurb'
import Card from 'components/shared/Card'
import ShareBtn from 'components/shared/ShareBtn'
import Badges from 'components/Users/Badges'
import Button from 'components/shared/Button'
import { getRoute } from 'utils'
import { toDate } from 'utils/conversions'
import { CalendarIcon, MapMarkerIcon } from 'components/shared/Icons'
import UserStateStore from 'state/UserStateStore'

export default ({
  user,
  showMessage,
  showViewProfile,
  className,
  ...props
}) => {
  if (!user) {
    return <div />
  }
  const currentUser = UserStateStore.user
  const { firstName, createdAt, location, website } = user
  return (
    <Card>
      <div className={[styles.container, className].join(' ')}>
        <UserBlurb
          containerClass={styles.avatar}
          user={user}
          linkProfile={false}
          size="105px"
        />
        <h2 className={styles.title}>{user.firstName}</h2>
        {location && location.city && (
          <div className={styles.userRow}>
            <MapMarkerIcon />
            {location.city}
          </div>
        )}
        {createdAt && (
          <div className={styles.userRow}>
            <CalendarIcon />
            Joined {toDate(user.createdAt, 'MMM YYYY')}
          </div>
        )}
        <Badges user={user} />
        {showMessage && (
          <Button
            full
            className={styles.messageBtn}
            to={`/message?recipient=${user.id}`}
            disabled={currentUser && currentUser.id == user.id}
          >
            Message
          </Button>
        )}
        {showViewProfile && (
          <Button
            full
            className={styles.messageBtn}
            to={getRoute('PROFILE', {
              id: user.id,
            })}
          >
            View Profile
          </Button>
        )}
      </div>
    </Card>
  )
}
