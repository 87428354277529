import React from 'react'
import Moment from 'moment'

import styles from './Badges.module.scss'
import { GreenCheckIcon } from 'components/shared/Icons'
Moment.locale('en')

const PhoneBadge = ({ phoneVerified }) => {
  if (!phoneVerified) {
    return <div />
  }
  return (
    <div className={styles.badgeRow}>
      <GreenCheckIcon />
      Phone Verified
    </div>
  )
}

const EmailBadge = ({ emailVerified }) => {
  if (!emailVerified) {
    return <div />
  }
  return (
    <div className={styles.badgeRow}>
      <GreenCheckIcon />
      Email Verified
    </div>
  )
}

const SocialBadge = ({ socialVerified }) => {
  if (!socialVerified) {
    return <div />
  }
  return (
    <div className={styles.badgeRow}>
      <GreenCheckIcon />
      Social Verified
    </div>
  )
}

const NewUserBadge = ({ createdAt }) => {
  if (createdAt && typeof createdAt.toDate === 'function') {
    const age_months = Moment(createdAt.toDate()).diff(Moment(), 'months')
    if (age_months > -2) {
      return <div />
    } else {
      return <div />
    }
  } else {
    return <div />
  }
}

export default ({ user }) => {
  const { phoneVerified, emailVerified, socialVerified, createdAt } = user

  return (
    <div className={styles.badgeContainer}>
      <PhoneBadge phoneVerified={phoneVerified} />
      <EmailBadge emailVerified={emailVerified} />
      <SocialBadge socialVerified={socialVerified} />
      <NewUserBadge createdAt={createdAt} />
    </div>
  )
}
