import React from 'react'

import styles from './Switcher.module.scss'
import TabNavigator from 'components/shared/TabNavigator'

export default ({ children, tabs, ...props }) => (
  <TabNavigator
    {...props}
    tabs={tabs}
    tabClass={styles.switcherItem}
    tabContainerClass={styles.switcherContainer}
    tabContainerProps={{
      style: { '--num_menu_items': tabs.length, '--index': 0 },
    }}
    activeTabClass={styles.selected}
  >
    {children}
  </TabNavigator>
)
