import React from 'react'

import styles from './ProfileTab.module.scss'
import {
  FormInput,
  Uploader,
  FormRow,
  FormCol,
  FormButtonsElement,
} from 'components/shared/Forms'
import { AuthService, DatabaseService } from 'services'
import Card from 'components/shared/Card'

export default class ProfileTabBase extends React.Component {
  static defaultProps = {
    profile: {},
  }

  state = {
    user_img: this.props.profile.picture,
    user_firstName: this.props.profile.firstName,
    user_lastName: this.props.profile.lastName,
    user_email: this.props.profile.email,
    user_city: this.props.profile.city,
    user_phone: this.props.profile.phone,
    user_bio: this.props.profile.description,
    saved: true,
    saving: false,
  }

  onUpdate = async data => {
    try {
      if (data.picture) {
        await AuthService.auth.currentUser.updateProfile({
          photoURL: data.picture,
        })
      }
      await DatabaseService.updateCurrentUser(data)
    } catch (error) {
      return error
    }
  }

  _submit = async () => {
    this.setState({ saving: true })
    let data = {}
    data.firstName = this.state.user_firstName
    data.lastName = this.state.user_lastName
    data.picture = this.state.user_img
    data.description = this.state.user_bio
    data.city = this.state.user_city
    data.phone = this.state.user_phone

    const error = await this.onUpdate(data)
    if (error) {
      return this.setState({ saving: false, error })
    }
    this.setState({ saving: false, saved: true })
  }

  onChange = event => {
    this.setState({ saved: false, [event.target.name]: event.target.value })
  }

  render() {
    const {
      user_firstName,
      user_lastName,
      user_img,
      user_email,
      user_city,
      user_bio,
      user_phone,
      saved,
      saving,
    } = this.state

    return (
      <Card>
        <form>
          <FormRow>
            <FormCol>
              <FormInput label="First Name">
                <input
                  type="text"
                  name="user_firstName"
                  value={user_firstName}
                  maxLength="250"
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="Last Name">
                <input
                  type="text"
                  name="user_lastName"
                  value={user_lastName}
                  maxLength="250"
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="City">
                <input
                  type="text"
                  name="user_city"
                  value={user_city}
                  maxLength="250"
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="Phone Number">
                <input
                  type="tel"
                  name="user_phone"
                  value={user_phone}
                  maxLength="250"
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="Bio">
                <textarea
                  type="text"
                  name="user_bio"
                  value={user_bio}
                  maxLength="450"
                  rows="4"
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>

          <FormButtonsElement
            savedText={saving ? 'Saving...' : saved ? 'Saved' : 'Save'}
            disabled={saved}
            submit={() => this._submit()}
          />
        </form>
      </Card>
    )
  }
}
