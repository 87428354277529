import React from 'react'

import ItemRentalItem from './ItemRentalItem.js'
import List from 'components/shared/Lists/List'
import { RentalEmpty } from 'components/DashboardPage/EmptyLists'

export default ({ items, lending }) => (
  <List
    items={items}
    lending={lending}
    emptyComponent={RentalEmpty}
    itemComponent={ItemRentalItem}
  />
)
